import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import app from './slice';
import session from './session/slice';
import charts from './charts/slice';
import questionnaire from './questionnaires/slice';

export const store = configureStore({
  reducer: {
    app,
    session,
    charts,
    questionnaire,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type Store = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
