import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { appConfig } from '../config';
import { auth } from '../firebase';
import { logout } from '../redux-file/session/actions';
import type { Store } from '../redux-file/store';
import { toast } from 'react-toastify';

let store: Store; /* injected externally */
export const injectStore = (injectedStore: Store) => {
  store = injectedStore;
};

export const handleToastError = (error: any) => {
  if ([400, 422].includes(error?.response?.status)) {
    const detail = error?.response?.data?.detail;
    if (typeof detail === 'string') {
      toast.error(detail);
      return;
    }
    for (const { msg } of error?.response?.data?.errors || [])
      toast.error(String(msg));
  }
  if (error?.response?.status === 403) toast.error('Forbidden');
  if (error?.response?.status === 500) toast.error('Internal Server Error');
};

const configureClient = (
  client: AxiosInstance,
  useAuth: boolean
): AxiosInstance => {
  client.interceptors.request.use(
    async (req) => {
      if (useAuth) {
        const user = auth().currentUser;
        if (!user) {
          // TODO: handle unauthorized
          throw Error('not logged in');
        }
        const token = await user.getIdToken();
        req.headers!.Authorization = `Bearer ${token}`;
      }
      req.headers!['Accept-Language'] = store.getState().app?.language ?? 'en';
      return req;
    },
    (error) => {
      Sentry.captureException(error);
      return error;
    }
  );
  client.interceptors.response.use(
    (res) => {
      if (useAuth) {
        const requestAuthorized = !!res.config.headers?.Authorization;
        if (requestAuthorized && res.status === 401) {
          store.dispatch(logout());
        }
      }
      return res;
    },
    (error) => {
      Sentry.captureException(error);
      throw error;
    }
  );
  return client;
};

const buildClient = (baseUrl: string, useAuth: boolean): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: baseUrl,
    validateStatus: (status) => status >= 200 && status < 300,
    withCredentials: false,
  };
  const client = axios.create(config);
  return configureClient(client, useAuth);
};

/**
 * Axios client that performs authenticated requests
 */
export const client = buildClient(appConfig.backendApiUrl, true);
/**
 * Axios client that performs anonymous requests without auth
 */
export const anonymousClient = buildClient(appConfig.backendApiUrl, false);
