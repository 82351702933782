import styled from 'styled-components';
import image from '../../../images/onboardingBG.png';

export const Container = styled.div`
  width: 984px;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 2.75rem;
  margin-bottom: 4rem;
`;

export const TopBox = styled.div`
  border-radius: 16px 16px 0 0;
  background-image: url(${image});
  background-size: cover;
  height: 195px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TopBoxTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 1.5rem;
`;

export const TopBoxSubTitle = styled.p`
  font-weight: 500;
  text-shadow: 1px 3px 10px rgba(0, 0, 0, 0.4);
  font-size: 24px;
`;

export const BottomBox = styled.div`
  padding: 2.25rem 1.5rem 4rem;
  border-radius: 0 0 16px 16px;
  border: 1px solid #b6bbb9;
  border-top: 0;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #132c1d;
  letter-spacing: 0.03em;
  margin-bottom: 2.75rem;
`;
