/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ChangeableForm, QuiestionnaireState } from './interfaces';
import {
  setSelectedDate,
  getEmployeeSurvey,
  getDirectEmissionsData,
  getElecricityEmissionsData,
  getPurchasedGoodsAndServicesData,
  getCapitalGoodsData,
  getTransportationAndDistributionData,
  getWasteAndOtherActivitiesData,
  getAssetsFranchisesAndInvestmentsData,
  postEmployeeSurveyData,
  getSoldProductsData,
  getFuelProduct,
  getSoldProductsFinalProductDirect,
  getSoldProductsFinalProductIndirect,
  getDirectEmissionsDataEmissionShares,
  getElecricityEmissionsDataEmissionShares,
  getTransportationAndDistributionDataEmissionShares,
  getWasteAndOtherActivitiesDataEmissionShares,
  getAssetsFranchisesAndInvestmentsEmissionSharesData,
} from './thunks';

const initialState: QuiestionnaireState = {
  currentSlug: '',

  questionnaireData: null,
  questionnairePage: null,
  questionnairePageLoading: true,
  questionnairePageError: false,

  employeeSurveyData: null,
  employeeSurveyPage: null,
  employeeSurveyPageLoading: true,
  employeeSurveyPageError: false,
  sentEmployeeData: undefined,
  sentEmployeeDataLoading: true,
  employeeSurveyPartError: [],

  selectedDate: new Date(),
  selectedFormset: {
    formId: null,
    dataId: null,
  },
  formError: false,

  uploadError: false,

  directEmissions: {
    stationary_combustion: [],
    mobile_combustion_consumptionwise: [],
    mobile_combustion_distancewise: [],
    fugutive_emissions: [],
    process_emissions: [],
  },
  directEmissionsError: false,
  directEmissionsLoading: true,

  directEmissionsEmissionShares: {
    stationary: [],
    fugutive_emissions: [],
    mobile_combustion_consumptionwise: [],
    mobile_combustion_distancewise: [],
    process_emissions: [],
  },

  electricityEmission: {
    electricity_usage: [],
    steam_usage: [],
    heat_usage: [],
    cooling_usage: [],
    electric_vehicles_consumptionwise_usage: [],
    electric_vehicles_distancewise_usage: [],
  },
  electricityEmissionError: false,
  electricityEmissionLoading: true,

  purchasedGoodsAndServices: [],
  purchasedGoodsAndServicesError: false,
  purchasedGoodsAndServicesLoading: true,

  capitalGoods: [],
  capitalGoodsError: false,
  capitalGoodsLoading: true,

  transportationAndDistribution: {
    transportation: {
      upstream: [],
      downstream: [],
    },
    distribution: {
      upstream: [],
      downstream: [],
    },
  },
  transportationAndDistributionError: false,
  transportationAndDistributionLoading: true,

  wasteData: {
    waste_data: [],
    other_fuel_related_activities_data: [],
    other_energy_related_activities: [],
    other_emissions: [],
  },
  wasteDataError: false,
  wasteDataLoading: true,

  assetsData: {
    downstream_leased_assets: [],
    franchises: [],
    downstream_leased_buildings: [],
    investments: [],
    upstream_leased_assets: [],
    upstream_leased_buildings: [],
  },
  assetsDataLoading: true,
  assetsDataError: false,

  soldProducts: [],
  soldProductsError: false,

  soldProductFuel: null,
  soldProductsFuelError: false,

  soldProductDirect: null,
  soldProductsDirectError: false,

  soldProductIndirect: null,
  soldProductsIndirectError: false,

  electricityEmissionEmissionShares: {
    cooling_usage: [],
    electric_vehicles_consumptionwise_usage: [],
    electric_vehicles_distancewise_usage: [],
    electricity_usage: [],
    heat_usage: [],
    steam_usage: [],
  },

  transportationAndDistributionEmissionShares: {
    distribution_downstream: [],
    distribution_upstream: [],
    transportation_downstream: [],
    transportation_upstream: [],
  },

  wasteDataEmissionShares: {
    other_emissions: [],
    other_energy_related_activities: [],
    other_fuel_related_activities_data: [],
    waste_data: [],
  },

  assetsDataEmissionShares: {
    downstream_leased_assets: [],
    downstream_leased_buildings: [],
    franchises: [],
    investments: [],
    upstream_leased_assets: [],
    upstream_leased_buildings: [],
  },
};

export const questionnairesSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedDate.fulfilled, (state, action) => {
        state.selectedDate = action.payload;
      })
      .addCase(getEmployeeSurvey.fulfilled, (state, action) => {
        state.employeeSurveyPage = action.payload.data;
        state.currentSlug = action.payload.slug;
        const newEmployeeSurveyData: ChangeableForm[] = [];
        action.payload.data.sections.forEach((section) => {
          section.formsets.forEach((formset) => {
            const emptyData = { id: formset.id, values: [] };
            newEmployeeSurveyData.push({
              id: formset.form.id,
              data: [
                // ...formset.form.data, - przez to do formularza trafiały już wczesniej wypelnione  dane
                emptyData,
              ],
            });
          });
        });
        state.employeeSurveyData = [...newEmployeeSurveyData];
        state.employeeSurveyPageLoading = false;
        state.employeeSurveyPageError = false;
      })
      .addCase(getEmployeeSurvey.pending, (state) => {
        state.employeeSurveyPageLoading = true;
        state.questionnairePageError = false;
      })
      .addCase(getEmployeeSurvey.rejected, (state) => {
        state.employeeSurveyData = initialState.employeeSurveyData;
        state.employeeSurveyPageLoading = false;
        state.employeeSurveyPageError = true;
      })

      .addCase(getDirectEmissionsData.fulfilled, (state, action) => {
        state.directEmissions = action.payload;
        state.directEmissionsError = false;
        state.directEmissionsLoading = false;
      })
      .addCase(getDirectEmissionsData.pending, (state) => {
        state.directEmissionsError = false;
        state.directEmissionsLoading = true;
      })
      .addCase(getDirectEmissionsData.rejected, (state) => {
        state.directEmissions = {
          stationary_combustion: [],
          mobile_combustion_consumptionwise: [],
          mobile_combustion_distancewise: [],
          fugutive_emissions: [],
          process_emissions: [],
        };
        state.directEmissionsError = true;
        state.directEmissionsLoading = false;
      })

      .addCase(getElecricityEmissionsData.fulfilled, (state, action) => {
        state.electricityEmission = action.payload;
        state.electricityEmissionError = false;
        state.electricityEmissionLoading = false;
      })
      .addCase(getElecricityEmissionsData.pending, (state) => {
        state.electricityEmissionError = false;
        state.electricityEmissionLoading = true;
      })
      .addCase(getElecricityEmissionsData.rejected, (state) => {
        state.electricityEmission = {
          electricity_usage: [],
          steam_usage: [],
          heat_usage: [],
          cooling_usage: [],
          electric_vehicles_consumptionwise_usage: [],
          electric_vehicles_distancewise_usage: [],
        };
        state.electricityEmissionError = true;
        state.electricityEmissionLoading = false;
      })

      .addCase(getPurchasedGoodsAndServicesData.pending, (state) => {
        state.purchasedGoodsAndServicesLoading = true;
        state.purchasedGoodsAndServicesError = false;
      })
      .addCase(getPurchasedGoodsAndServicesData.rejected, (state) => {
        state.purchasedGoodsAndServicesLoading = false;
        state.purchasedGoodsAndServicesError = true;
        state.purchasedGoodsAndServices = { data: [] };
      })
      .addCase(getPurchasedGoodsAndServicesData.fulfilled, (state, action) => {
        const combinedData = action.payload.data.map((el: any, i: number) => ({
          ...el,
          ...action.payload.summary[i],
        }));
        state.purchasedGoodsAndServicesLoading = false;
        state.purchasedGoodsAndServicesError = false;
        state.purchasedGoodsAndServices = combinedData;
      })

      .addCase(getCapitalGoodsData.pending, (state) => {
        state.capitalGoodsLoading = true;
        state.capitalGoodsError = false;
      })
      .addCase(getCapitalGoodsData.rejected, (state) => {
        state.capitalGoodsLoading = false;
        state.capitalGoodsError = true;
        state.capitalGoods = { data: [] };
      })
      .addCase(getCapitalGoodsData.fulfilled, (state, action) => {
        const combinedData = action.payload.data.map((el: any, i: number) => ({
          ...el,
          ...action.payload.summary[i],
        }));
        state.capitalGoodsLoading = false;
        state.capitalGoodsError = false;
        state.capitalGoods = combinedData;
      })

      .addCase(getTransportationAndDistributionData.pending, (state) => {
        state.transportationAndDistributionLoading = true;
        state.transportationAndDistributionError = false;
      })
      .addCase(getTransportationAndDistributionData.rejected, (state) => {
        state.transportationAndDistributionLoading = false;
        state.transportationAndDistributionError = true;
        state.transportationAndDistribution = {
          transportation: {
            upstream: [],
            downstream: [],
          },
          distribution: {
            upstream: [],
            downstream: [],
          },
        };
      })
      .addCase(
        getTransportationAndDistributionData.fulfilled,
        (state, action) => {
          state.transportationAndDistributionLoading = false;
          state.transportationAndDistributionError = false;
          state.transportationAndDistribution = action.payload;
        }
      )

      .addCase(getWasteAndOtherActivitiesData.pending, (state) => {
        state.wasteDataLoading = true;
        state.wasteDataError = false;
      })
      .addCase(getWasteAndOtherActivitiesData.rejected, (state) => {
        state.wasteDataLoading = false;
        state.wasteDataError = true;
        state.wasteData = {
          waste_data: [],
          other_fuel_related_activities_data: [],
          other_energy_related_activities: [],
          other_emissions: [],
        };
      })
      .addCase(getWasteAndOtherActivitiesData.fulfilled, (state, action) => {
        state.wasteDataLoading = false;
        state.wasteDataError = false;
        state.wasteData = action.payload;
      })

      .addCase(getAssetsFranchisesAndInvestmentsData.pending, (state) => {
        state.assetsDataLoading = true;
        state.assetsDataError = false;
      })
      .addCase(getAssetsFranchisesAndInvestmentsData.rejected, (state) => {
        state.assetsDataLoading = false;
        state.assetsDataError = true;
        state.assetsData = {
          upstream_leased_buildings: [],
          upstream_leased_assets: [],
          downstream_leased_buildings: [],
          downstream_leased_assets: [],
          franchises: [],
          investments: [],
        };
      })
      .addCase(
        getAssetsFranchisesAndInvestmentsData.fulfilled,
        (state, action) => {
          state.assetsDataLoading = false;
          state.assetsDataError = false;
          state.assetsData = action.payload;
        }
      )
      .addCase(postEmployeeSurveyData.fulfilled, (state) => {
        state.sentEmployeeData = true;
        state.sentEmployeeDataLoading = false;
      })
      .addCase(postEmployeeSurveyData.rejected, (state) => {
        state.sentEmployeeData = false;
        state.sentEmployeeDataLoading = false;
      })
      .addCase(postEmployeeSurveyData.pending, (state) => {
        state.sentEmployeeData = false;
        state.sentEmployeeDataLoading = true;
      })

      .addCase(getSoldProductsData.fulfilled, (state, action) => {
        state.soldProducts = action.payload;
        state.soldProductsError = false;
      })

      .addCase(getFuelProduct.fulfilled, (state, action) => {
        state.soldProductFuel = action.payload;
        state.soldProductsFuelError = false;
      })
      .addCase(getFuelProduct.rejected, (state) => {
        state.soldProductFuel = null;
        state.soldProductsFuelError = true;
      })

      .addCase(getSoldProductsFinalProductDirect.fulfilled, (state, action) => {
        state.soldProductDirect = action.payload;
        state.soldProductsDirectError = false;
      })
      .addCase(getSoldProductsFinalProductDirect.rejected, (state) => {
        state.soldProductDirect = null;
        state.soldProductsDirectError = true;
      })

      .addCase(
        getSoldProductsFinalProductIndirect.fulfilled,
        (state, action) => {
          state.soldProductIndirect = action.payload;
          state.soldProductsIndirectError = false;
        }
      )
      .addCase(getSoldProductsFinalProductIndirect.rejected, (state) => {
        state.soldProductIndirect = null;
        state.soldProductsIndirectError = true;
      })
      .addCase(
        getDirectEmissionsDataEmissionShares.fulfilled,
        (state, action) => {
          state.directEmissionsEmissionShares = action.payload;
        }
      )
      .addCase(
        getElecricityEmissionsDataEmissionShares.fulfilled,
        (state, action) => {
          state.electricityEmissionEmissionShares = action.payload;
        }
      )
      .addCase(
        getTransportationAndDistributionDataEmissionShares.fulfilled,
        (state, action) => {
          state.transportationAndDistributionEmissionShares = action.payload;
        }
      )
      .addCase(
        getWasteAndOtherActivitiesDataEmissionShares.fulfilled,
        (state, action) => {
          state.wasteDataEmissionShares = action.payload;
        }
      )
      .addCase(
        getAssetsFranchisesAndInvestmentsEmissionSharesData.fulfilled,
        (state, action) => {
          state.assetsDataEmissionShares = action.payload;
        }
      );
  },
});

export default questionnairesSlice.reducer;
