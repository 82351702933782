import React from 'react';
import './webflow.css';
import './envirly-landing-page.webflow.css';
import { useTranslation } from 'react-i18next';
import linkedInIcon from './images/LinkedIn_icon.png';
import phoneIcon from './images/smartphone-24px-1.png';
import mailIcon from './images/Mail_icon.png';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <div id="Footer" className="section footer wf-section">
      <div className="container-7 w-container">
        <div className="columns-3 w-row">
          <div className="column-13 w-col w-col-6">
            <div className="w-row">
              <div className="column-16 w-col w-col-6">
                <div className="logo-text footer">{t('footer.envirly')}</div>
                <div className="footer-slogan">{t('footer.by-q')}</div>
                <div className="w-layout-grid grid-3">
                  <p
                    id="w-node-_1ca72930-f89d-9cd6-538f-a0ea3a68f9ff-b07ffbfc"
                    className="paragraph-7"
                  >
                    {t('footer.dont-miss-out')}
                  </p>
                  <a
                    id="w-node-eb732174-1af1-c1a4-f7c8-ce3a3f02715c-b07ffbfc"
                    href="https://www.linkedin.com/company/envirly"
                    target="_blank"
                    className="link-block w-inline-block"
                    rel="noreferrer"
                  >
                    <img
                      src={linkedInIcon}
                      loading="lazy"
                      width="32"
                      id="LinkedIn"
                      alt="Icon - Linkedin"
                      className="image-13"
                    />
                  </a>
                </div>
              </div>
              <div className="w-col w-col-6">
                <h4 className="footer-title">{t('footer.address-title')}</h4>
                <p className="paragraph-8-copy">{t('footer.company-name')}</p>
                <p className="paragraph-8">
                  {t('footer.company-street')}
                  <br />
                  {t('footer.company-city')}
                </p>
              </div>
            </div>
          </div>
          <div className="column-15 w-col w-col-6">
            <div className="w-row">
              <div className="w-col w-col-6">
                <h4 className="footer-title">{t('footer.see-more')}</h4>
                <a
                  href="https://www.envirly.pl/polityka-prywatnosci.pdf"
                  className="page-link in-footer"
                >
                  {t('footer.privacy-policy')}
                </a>
              </div>
              <div className="w-col w-col-6">
                <h4 className="footer-title">{t('footer.contact')}</h4>
                <div className="w-layout-grid grid-4">
                  <img
                    src={phoneIcon}
                    loading="lazy"
                    id="w-node-d4005626-7cc2-4d3e-7bde-96ac39106f54-b07ffbfc"
                    alt="Icon - email"
                    className="image-14"
                  />
                  <p
                    id="w-node-_531aa95f-3e9b-d991-765a-763590ab4880-b07ffbfc"
                    className="paragraph-9"
                  >
                    {t('footer.phone-us')}
                  </p>
                  <img
                    src={phoneIcon}
                    loading="lazy"
                    id="w-node-_9b1d898d-b4fd-f38f-fd8f-0929e3030a73-b07ffbfc"
                    alt="Icon - email"
                    className="image-15"
                  />
                  <p
                    id="w-node-_5ff9f001-3e5a-f052-e1b9-46dd1d51ba79-b07ffbfc"
                    className="paragraph-10"
                  >
                    {t('footer.phone-pl')}
                  </p>
                  <img
                    src={mailIcon}
                    loading="lazy"
                    id="w-node-e1ef6368-dcb7-1b84-849f-cdcf553c4149-b07ffbfc"
                    alt="Icon - contact"
                    className="image-21"
                  />
                  <p
                    id="w-node-f84dd9d5-4514-e785-2d16-49301a83d2a8-b07ffbfc"
                    className="paragraph-10"
                  >
                    {t('footer.email')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
