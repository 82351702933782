import styled from 'styled-components';

export const Container = styled.header`
  height: 5.5rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 1rem 2.5rem 1rem 4rem;
  justify-content: space-between;
  color: #606462;
  box-shadow: 0 9px 6px -5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2.5rem;
  width: 100%;

  & > div {
    display: flex;
    align-items: center;
  }

  a {
    width: 3rem;
    height: 3rem;
    display: block;
  }

  button img,
  a img {
    width: 3rem;
    height: 3rem;
  }
`;

export default { Container };
