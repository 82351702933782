/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { auth } from '../../firebase';
import { handleLogout } from '../../utils';
import { setLoggedIn } from './actions';
import { SessionState } from './interfaces';
import {
  getOrganizations,
  getSettingsOrgs,
  getSettingsSuperOrg,
  getSettingsUserData,
  getUserData,
  setManagedCompanies,
  setOrganizationId,
} from './thunks';
import { getOrganization } from '../thunk';

const initialState: SessionState = {
  user: undefined,
  userError: false,
  isLoggedIn: undefined,
  shouldCompleteOnboarding: false,
  isSuperadmin: undefined,
  organizationId: 0,
  organizationTags: [],
  managedCompanies: [],
  managedCompaniesLoading: true,

  settings: {
    user: {
      first_name: '',
      last_name: '',
      email: '',
      position: '',
      phone_number: '',
    },
    orgs: [
      {
        id: 0,
        country: '',
        name: '',
        co2: {
          industry: undefined,
        },
        user_can_edit: false,
        vat_identifier: '',
      },
    ],
    super_org: {
      name: '',
      id: 0,
      user_can_edit: false,
    },
    users_permissions: [],
  },
};

export const createState = (state: Partial<SessionState>) => ({
  session: { ...initialState, ...state },
});

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLoggedIn, (state, { payload: isLoggedIn }) => {
        const user = auth().currentUser;
        if (state.isLoggedIn && !isLoggedIn) {
          Object.assign(state, initialState);
          handleLogout();
        } else {
          state.isLoggedIn = isLoggedIn ? !!user : isLoggedIn;
        }
      })
      .addCase(
        getUserData.fulfilled,
        (
          state,
          {
            payload: {
              id: userId,
              language,
              superorganization,
              organization,
              is_superuser: isSuperuser,
              email,
              available_products,
            },
          }
        ) => {
          state.user = {
            userId,
            language,
            superorganization,
            organization,
            isSuperuser,
            email,
            products: available_products,
          };
          state.userError = false;
          if (!!organization) {
            state.organizationId = organization.id;
          }
          state.isSuperadmin = !!superorganization;
        }
      )
      .addCase(getUserData.rejected, (state) => {
        state.user = undefined;
        state.userError = true;
      })
      .addCase(getOrganization.fulfilled, (state, action) => {
        state.shouldCompleteOnboarding = !(
          action.payload.co2?.is_onboarding_completed ?? true
        );
        state.organizationTags = action.payload.tags;
      })
      .addCase(setOrganizationId.fulfilled, (state, action) => {
        state.organizationId = action.payload;
      })
      .addCase(setManagedCompanies.fulfilled, (state, action) => {
        state.managedCompanies = action.payload;
        state.managedCompaniesLoading = false;
      })
      .addCase(getSettingsUserData.fulfilled, (state, action) => {
        state.settings.user = action.payload;
      })
      .addCase(getSettingsOrgs.fulfilled, (state, action) => {
        state.settings.orgs = action.payload;
      })
      .addCase(getSettingsSuperOrg.fulfilled, (state, action) => {
        state.settings.super_org = action.payload;
      })
      .addCase(getOrganizations.fulfilled, (state, action) => {
        // TODO: reconsider when we support superadmin for products other than CO2
        state.organizations = action.payload.filter((x) => !!x.co2);
      });
  },
});

export default sessionSlice.reducer;
