/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getOrganizationsAPI,
  getSettingsOrgsAPI,
  getSettingsSuperOrgAPI,
  getSettingsUserDataAPI,
  getUserAPI,
  postCo2OrganizationOnboardingAPI,
} from '../../utils';
import {
  Co2OrganizationOnboardingSchema,
  ProductPlatform,
} from '../../utils/api.interfaces';

export const getUserData = createAsyncThunk(
  'getUserData',
  async () => await getUserAPI()
);

export const postCo2OrganizationOnboarding = createAsyncThunk(
  'postCo2OrganizationOnboarding',
  async ({
    data,
    orgId,
  }: {
    data: Co2OrganizationOnboardingSchema;
    orgId: number;
  }) => await postCo2OrganizationOnboardingAPI(orgId, data)
);

export const setOrganizationId = createAsyncThunk(
  'setOrganizationId',
  async (id: number) => {
    localStorage.setItem('orgId', `${id}`);
    return id;
  }
);

export const getOrganizations = createAsyncThunk(
  'getOrganizations',
  async (data?: ProductPlatform[]) => await getOrganizationsAPI(data || [])
);

export const setManagedCompanies = createAsyncThunk(
  'setManagedCompanies',
  async (data: any) => data
);

export const getSettingsUserData = createAsyncThunk(
  'getSettingsUserData',
  async () => await getSettingsUserDataAPI()
);

export const getSettingsOrgs = createAsyncThunk(
  'getSettingsOrgs',
  async () => await getSettingsOrgsAPI()
);

export const getSettingsSuperOrg = createAsyncThunk(
  'getSettingsSuperOrg',
  async () => await getSettingsSuperOrgAPI()
);
