const themeColors = {
  dark: '#2F2F2F',
  grayIcons: '#606462',
  greenLogotype: '#165D5C',
  greenBrand: '#388276',
  greenHover: '#499387',
  greenPressed: '#58A497',
  redParagraphs: '#AD5356',
  redGraphics: '#CF6367',
  grayInactive: '#B6BBB9',
  strokePressed: '#ddd',
  hoverButton: '#F2F2F2',
  background: '#F9F9F9',
  pureWhite: '#fff',
  greenCalendarLight: '#B2E5DD',
  greenCalendar: '#8AC8BE',
};

export default themeColors;
