import React, { useEffect, useState } from 'react';
import {
  Button,
  LangSelect,
  LangSelectOverlay,
  LangSelectWrapper,
} from './index.styles';
import { useTranslation } from 'react-i18next';

export default function LanguagePickerLocal({ color }: { color?: string }) {
  const [show, setShow] = useState(false);
  const { i18n } = useTranslation();
  const [language, setLeanguage] = useState<string>(i18n.language);

  const changeLanguage = (lang: string) => {
    setLeanguage(lang);
    localStorage.setItem('lang', lang);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    setLeanguage(localStorage.getItem('lang') || 'pl');
  }, [])

  return (
    <div>
      <Button color={color} type="button" onClick={() => setShow(true)}>
        {language === 'en' ? 'EN' : 'PL'}
      </Button>
      {show && (
        <>
          <LangSelectOverlay onClick={() => setShow(false)} />
          <LangSelectWrapper>
            <LangSelect>
              <Button
                type="button"
                onClick={() => {
                  changeLanguage('en');
                  setShow(false);
                }}
              >
                EN
              </Button>
              <Button
                type="button"
                onClick={() => {
                  changeLanguage('pl');
                  setShow(false);
                }}
              >
                PL
              </Button>
            </LangSelect>
          </LangSelectWrapper>
        </>
      )}
    </div>
  );
}
