/* eslint-disable prefer-template*/
import { anonymousClient, client } from './api-client';
import { Stays } from '../redux-file/questionnaires/interfaces';
import { SettingsOrg, SettingsUser } from '../redux-file/session/interfaces';
import {
  Co2OrganizationOnboardingSchema,
  OrganizationDataOutDetailedSchema,
  OrganizationDataOutSchema,
  ProductPlatform,
  UserSchema,
} from './api.interfaces';

// TODO: Add type annotations to endpoints below

export const getTargetsAPI = async (organizationId: number) =>
  (await client.get(`/web/v2/targets?organization_id=${organizationId}`)).data;

export const getTargetAPI = async (organizationId: number, targetId: string) =>
  (
    await client.get(
      `/web/v2/target/${targetId}?organization_id=${organizationId}`
    )
  ).data;

export const deleteTargetAPI = async (
  organizationId: number,
  targetId: string
) =>
  (
    await client.delete(
      `/web/v2/target/${targetId}?organization_id=${organizationId}`
    )
  ).data;

export const createTargetAPI = async (
  orgId: number,
  commit: boolean,
  method: string,
  start_period: string,
  end_period: string,
  target_value: number,
  comment: string,
  domain: string,
  id?: number
) => {
  const payload = {
    method,
    target_value,
    start_period,
    end_period,
    comment,
    domain,
    id,
  };

  return (
    await client.post(
      `/web/v2/target?organization_id=${orgId}&commit=${commit}`,
      payload
    )
  ).data;
};

export const updateTargetAPI = async (
  orgId: number,
  targetId: string,
  commit: boolean,
  method?: string,
  end_period?: string,
  domain?: string,
  target_value?: number,
  comment?: string
) =>
  (
    await client.patch(
      `/web/v2/target/${targetId}?organization_id=${orgId}&commit=${commit}`,
      {
        method,
        end_period,
        domain,
        target_value,
        comment,
      }
    )
  ).data;

export const getCarbonFootprintAPI = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/carbon-footprint?start=${start}&end=${end}`
    )
  ).data;

export const getEmissionScope3API = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/emission-ranking-scope-3?start=${start}&end=${end}` //&depth=2
    )
  ).data;

export const getTotalEmissionAPI = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/total-emission-by-scope?start=${start}&end=${end}`
    )
  ).data;

export const getIndustryBenchmarksAPI = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/companys-emission-vs-industry-benchmarks?start=${start}&end=${end}`
    )
  ).data;

export const getBusinessTravelEmissionsAPI = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/business-travel-emissions?start=${start}&end=${end}`
    )
  ).data;

export const getEmployeesMeansOfTransportAPI = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/employees-means-of-transport?start=${start}&end=${end}`
    )
  ).data;

export const getEmissionPerScopeAPI = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/emission-per-scope?start=${start}&end=${end}`
    )
  ).data;

//export const getAllChartsAPI = async (
//  start: string,
//  end: string | null | undefined
//) =>
//  (await client.get(`/web/dashboardfigures/?start=${start}&end=${end}`)).data;

export const getAllChartsAPI = async (
  start: string,
  end: string | null | undefined,
  organizationId: number
) =>
  (
    await client.get(
      `/web/v2/dashboard_figures?start=${start}&end=${end}&organization_id=${organizationId}`
    )
  ).data;

export const getEmployeesStatsAPI = async (
  start: string,
  end: string | null | undefined
) =>
  (
    await client.get(
      `/web/dashboardfigures/employee-emissions?start=${start}&end=${end}`
    )
  ).data;

export const getRecommendationsAPI = async (orgId: number) =>
  (await client.get(`/web/organizationrecommendations/${orgId}`)).data;

export const getStatsTargetTotalAPI = async (orgId: number) =>
  (await client.get(`/organizations/${orgId}/stats/targets_count`)).data;

export const getStatsTargetCompletedAPI = async (orgId: number) =>
  (await client.get(`/organizations/${orgId}/stats/targets_achieved_count`))
    .data;

export const getTargetEmissionAPI = async (emissionType: string) =>
  (
    await client.get(
      `/emissions/aggregations?category=${emissionType}&by=created_at__date&func=sum`
    )
  ).data;

export const getUserAPI = async () =>
  (await client.get<UserSchema>(`/web/users/me`)).data;

export const getOrganizationAPI = async (
  idOrg: number,
  products: ProductPlatform[]
) =>
  (
    await client.get<OrganizationDataOutDetailedSchema>(
      `/web/organizations/${idOrg}`,
      {
        params: {
          include_product: products,
        },
        paramsSerializer: {
          indexes: null,
        },
      }
    )
  ).data;

export const getOrganizationsAPI = async (products: ProductPlatform[]) =>
  (
    await client.get<OrganizationDataOutSchema[]>(`/web/organizations`, {
      params: {
        include_product: products,
      },
      paramsSerializer: {
        indexes: null,
      },
    })
  ).data;

export const putLangAPI = async (language: string) =>
  (await client.put(`/web/users/me/language?language=${language}`)).data;

export const getTaskListAPI = async (orgId: number) =>
  (await client.get(`/web/organizationtasks/${orgId}`)).data;

export const postTaskListAPI = async (orgId: number, title: string) =>
  (
    await client.post(
      `/web/organizationtasks`,
      JSON.stringify({ title, checked: false, organization_id: orgId })
    )
  ).data;

export const patchTaskListAPI = async (
  orgId: number,
  id: number,
  checked: boolean
) =>
  (
    await client.patch(
      `/web/organizationtasks/${id}`,
      JSON.stringify({ checked, organization_id: orgId })
    )
  ).data;

export const deleteTaskListAPI = async (id: number) =>
  (await client.delete(`/web/organizationtasks/${id}`)).data;

export const getTotalEmissionDecreaseAPI = async (orgId: number, id: string) =>
  (
    await client.get(
      `/organizations/${orgId}/targets/${id}/graphs/total-emission-decrease`
    )
  ).data;

export const getPerEmployeeEmissionDecreaseAPI = async (
  orgId: number,
  id: string
) =>
  (
    await client.get(
      `/organizations/${orgId}/targets/${id}/graphs/per-employee-emission-decrease`
    )
  ).data;

export const getPerIncomeEmissionDecreaseAPI = async (
  orgId: number,
  id: string
) =>
  (
    await client.get(
      `/organizations/${orgId}/targets/${id}/graphs/per-income-emission-decrease`
    )
  ).data;

export const getOrgStatsApi = async (organizationId: number) =>
  (await client.get(`/web/v2/targetstats?organization_id=${organizationId}`))
    .data;

export const getNewQuestionnairePageAPIAnonymous = async (
  slug: string,
  period: string,
  apiKey: string,
  depth?: number
) =>
  (
    await anonymousClient.get(
      `/web/questionnairepages/${slug}?period=${period}${
        depth ? '&depth=' + depth : ''
      }&api_key=${apiKey}`
    )
  ).data;

export const getRespectEnergyRaportAPI = async (
  clientName: string,
  energySpent: number,
  factor: number
) =>
  (
    await client.get(
      `/web/reports/respect-energy?client_name=${clientName}&energy_spent=${energySpent}&factor=${factor}`,
      { responseType: 'arraybuffer' }
    )
  ).data;

export const getSuperadminStatsAndChartsAPI = async (
  start: string,
  end: string | null | undefined,
  orgIds: number[],
  orgMaxCount: number = 15,
  cancelSignal: AbortSignal | undefined = undefined
) =>
  (
    await client.post(
      `/web/v2/superdashboard_stats_and_figures?start=${start}&end=${end}&max_org_count=${orgMaxCount}`,
      {
        organization_ids: orgIds,
      },
      {
        signal: cancelSignal,
      }
    )
  ).data;

export const getDashboardStatsAPI = async (
  start: string,
  end: string | null | undefined,
  organizationId: number
) =>
  (
    await client.get(
      `/web/v2/dashboardstats?organization_id=${organizationId}&start=${start}&end=${end}`
    )
  ).data;

export const getAllEmployeeSurveyChartsAPI = async (
  organizationId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/employee_survey_figures?period=${period}&organization_id=${organizationId}`
    )
  ).data;

export const getEmployeeSurveyChartAPI = async (
  start: string,
  end: string | null | undefined,
  name: string
) =>
  (
    await client.get(
      `/web/employeesurveyfigures/${name}?start=${start}&end=${end}`
    )
  ).data;

export const getExcelFileAPI = async (
  slug: string,
  platform: 'LibreOffice' | 'GoogleSheets' | 'WindowsExcel'
) =>
  (
    await client.get(
      `/web/v2/questionnaires_xlsx/${slug}?platform=${platform}`,
      {
        responseType: 'arraybuffer',
      }
    )
  ).data;

export const postExcelFileAPI = async (slug: string, formData: FormData) =>
  await client.post(`/web/v2/questionnaires_xlsx/upload/${slug}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const createImpersonationToken = async (email: string) =>
  (
    await client.post(
      `/web/impersonation/`,
      JSON.stringify({ user_email: email })
    )
  ).data;

export const postEmployeeSurveyDataAPI = async (
  data: {
    stays: Stays[];
    average_number_of_days_on_home_office_per_week: number;
    business_trips: {
      type_of_trip_transport: string;
      times_in_period: null | number;
      average_distance_per_trip_km: null | number;
    }[];
    commute: {
      type_of_commute_transport: string;
      average_distance_one_way_km: null | number;
      times_per_week: null | number;
    }[];
  },
  token: string
) =>
  (
    await anonymousClient.put(
      `/web/v2/employeesurveys?token=${token}`,
      JSON.stringify({ ...data })
    )
  ).data;

export const getTokenAPI = async (
  orgId: string,
  period: string,
  beginDate: string | undefined,
  endDate: string | undefined
) =>
  (
    await client.get(
      `/web/v2/employeesurveys_token?period=${period}&organization_id=${orgId}&begin_date=${beginDate}${
        !!endDate ? `&end_date=${endDate}` : ''
      }`
    )
  ).data;

export const getDirectEmissionsDataAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/DirectEmissionsData?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const getDirectEmissionsDataEmissionSharesAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/DirectEmissionsData/emission_shares?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const putDirectEmissionsDataAPI = async (
  orgId: string,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/questionnaires/DirectEmissionsData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const getElecricityEmissionsDataAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/ElectiricyEmissionsData?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const getElecricityEmissionsDataEmissionSharesAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/ElectiricyEmissionsData/emission_shares?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const postElecricityEmissionsDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/questionnaires/ElectiricyEmissionsData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const postCo2OrganizationOnboardingAPI = async (
  orgId: number,
  data: Co2OrganizationOnboardingSchema
) => await client.post(`/web/organizations/${orgId}/co2/onboarding`, data);

export const getPurchasedGoodsAndServicesDataAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/PurchasedGoodsAndSerivesData?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const getAboutCompanyPeriodDataAPI = async (
  organizationId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/AboutCompanyData?organization_id=${organizationId}&period=${period}`
    )
  ).data;

export const putAboutCompanyPeriodDataAPI = async (
  organizationId: number,
  period: string,
  numberOfEmployees: number | null,
  incomeInReportingPeriod: number | null,
  organizationBoundaries: string | null
) =>
  (
    await client.put(
      `/web/v2/questionnaires/AboutCompanyData?organization_id=${organizationId}&period=${period}`,
      JSON.stringify({
        number_of_employees: numberOfEmployees,
        income_in_reporting_period: incomeInReportingPeriod,
        organization_boundaries: organizationBoundaries,
      })
    )
  ).data;

export const putPurchasedGoodsAndServicesDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/questionnaires/PurchasedGoodsAndSerivesData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ data })
  );

export const getCapitalGoodsDataAPI = async (orgId: number, period: string) =>
  (
    await client.get(
      `/web/v2/questionnaires/CapitalGoodsData?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const putCapitalGoodsDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/questionnaires/CapitalGoodsData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ data })
  );

export const getTransportationAndDistributionDataAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/TransportationAndDistributionData?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const getTransportationAndDistributionDataEmissionSharesAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/TransportationAndDistributionData/emission_shares?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const putTransportationAndDistributionDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/questionnaires/TransportationAndDistributionData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const getWasteAndOtherActivitiesDataAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/WasteAndOtherActivitiesData?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const getWasteAndOtherActivitiesDataEmissionSharesAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/WasteAndOtherActivitiesData/emission_shares?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const putWasteAndOtherActivitiesDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/questionnaires/WasteAndOtherActivitiesData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const getAssetsFranchisesAndInvestmentsDataAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/AssetsFranchisesAndInvestmentsData?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const getAssetsFranchisesAndInvestmentsEmissionSharesDataAPI = async (
  orgId: number,
  period: string
) =>
  (
    await client.get(
      `/web/v2/questionnaires/AssetsFranchisesAndInvestmentsData/emission_shares?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const putAssetsFranchisesAndInvestmentsDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/questionnaires/AssetsFranchisesAndInvestmentsData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const getConsentsApi = async () =>
  (await client.get(`/web/users/me/consents`)).data;

export const putConsentsApi = async (data: any) =>
  await client.put(`/web/users/me/consents`, JSON.stringify({ ...data }));

export const getSoldProductsDataAPI = async (orgId: number, period: string) =>
  (
    await client.get(
      `/web/v2/questionnaires_summaries/sold_products_summary?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const getFuelProductAPI = async (
  orgId: number,
  period: string,
  id: string
) =>
  (
    await client.get(
      `/web/v2/surveys/SoldProductsFuelOrFeedstockData/${encodeURI(
        id
      )}?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const deleteFuelProductAPI = async (
  orgId: number,
  period: string,
  id: string
) =>
  (
    await client.delete(
      `/web/v2/surveys/SoldProductsFuelOrFeedstockData/${encodeURI(
        id
      )}?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const createSoldProductsFuelOrFeedstockDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/surveys/SoldProductsFuelOrFeedstockData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const editSoldProductsFuelOrFeedstockDataAPI = async (
  orgId: number,
  participant_id: string,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/surveys/SoldProductsFuelOrFeedstockData?organization_id=${orgId}&participant_id=${encodeURI(
      participant_id
    )}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const createSoldProductsFinalProductDirectDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/surveys/FinalProductDirectData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const editSoldProductsFinalProductDirectDataAPI = async (
  orgId: number,
  participant_id: string,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/surveys/FinalProductDirectData?organization_id=${orgId}&participant_id=${encodeURI(
      participant_id
    )}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const getSoldProductsFinalProductDirectAPI = async (
  orgId: number,
  period: string,
  id: string
) =>
  (
    await client.get(
      `/web/v2/surveys/FinalProductDirectData/${encodeURI(
        id
      )}?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const deleteSoldProductsFinalProductDirectAPI = async (
  orgId: number,
  period: string,
  id: string
) =>
  (
    await client.delete(
      `/web/v2/surveys/FinalProductDirectData/${encodeURI(
        id
      )}?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const createSoldProductsFinalProductIndirectDataAPI = async (
  orgId: number,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/surveys/FinalProductIndirectData?organization_id=${orgId}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const editSoldProductsFinalProductIndirectDataAPI = async (
  orgId: number,
  participant_id: string,
  period: string,
  data: any
) =>
  await client.put(
    `/web/v2/surveys/FinalProductIndirectData?organization_id=${orgId}&participant_id=${encodeURI(
      participant_id
    )}&period=${period}`,
    JSON.stringify({ ...data })
  );

export const getSoldProductsFinalProductIndirectAPI = async (
  orgId: number,
  period: string,
  id: string
) =>
  (
    await client.get(
      `/web/v2/surveys/FinalProductIndirectData/${encodeURI(
        id
      )}?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const deleteSoldProductsFinalProductIndirectAPI = async (
  orgId: number,
  period: string,
  id: string
) =>
  (
    await client.delete(
      `/web/v2/surveys/FinalProductIndirectData/${encodeURI(
        id
      )}?organization_id=${orgId}&period=${period}`
    )
  ).data;

export const putPasswordAPI = async (password: string) =>
  await client.put(
    `/web/settings/password`,
    JSON.stringify({ new_password: password })
  );

export const getSettingsUserDataAPI = async () =>
  (await client.get(`/web/settings/user_data`)).data;

export const putSettingsUserDataAPI = async (data: SettingsUser) =>
  await client.put(`/web/settings/user_data`, JSON.stringify({ ...data }));

export const getSettingsOrgsAPI = async () =>
  (await client.get(`/web/settings/orgs`)).data;

export const putSettingsOrgAPI = async (
  orgId: number,
  data: Partial<SettingsOrg>
) => await client.put(`/web/settings/org/${orgId}`, data);

export const getSettingsSuperOrgAPI = async () =>
  (await client.get(`/web/settings/superorg_data`)).data;

export const putSettingsSuperOrgAPI = async (name: string) =>
  await client.put(`/web/settings/superorg_data`, JSON.stringify({ name }));
