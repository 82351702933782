import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  Container,
  LoginButton,
} from './views/Pages/Login/index.styles';
import OnboardingHeading from './views/components/OnboardingHeading';
import HeaderLogin from './views/components/HeaderLogin';
import Footer from './views/components/Footer';
import styles from './views/components/Layout/styles.module.css';
import { Link } from 'react-router-dom';

export default function App() {
  const { t } = useTranslation();
  return (
    <div className={styles.mainWithoutSidebar}>
      <Container>
        <HeaderLogin />
        <OnboardingHeading text={t('shutdown.header')}>
          <h3 dangerouslySetInnerHTML={{ __html: t('shutdown.text') }} />

          <ButtonContainer style={{ paddingTop: 50 }}>
            <LoginButton onClick={() => { window.location.href = t('shutdown.buttonHref'); }}>
              <span>{t('shutdown.button')}</span>
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                <path
                  d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
                  fill="white"
                />
              </svg>
            </LoginButton>
          </ButtonContainer>
        </OnboardingHeading>
      </Container>
      <Footer />
    </div>
  );
}
