import React from 'react';
import { useLocation } from 'react-router-dom';
import messageIcon from '../../../images/message.png';
import { Container } from './index.styles';
import Logo from '../Logo';
import LanguagePickerLocal from '../LanguagePickerLocal';

export default function HeaderLogin() {
  const { pathname } = useLocation();

  return (
    <Container>
      <div>{pathname.startsWith('/onboarding') && <Logo />}</div>
      <div>
        <a href="mailto:contact@envirly.com">
          <img src={messageIcon} alt="contact" />
        </a>
        <LanguagePickerLocal />
      </div>
    </Container>
  );
}
