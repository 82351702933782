import styled from 'styled-components';

export const Button = styled.button<{ color?: string }>`
  border: 0;
  background-color: ${({ color }) => color || '#fff'};
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  color: #606462;
  font-weight: 700;
  font-size: 18px;
  line-height: 48px;
  padding: 0;
`;

export const LangSelectWrapper = styled.div`
  position: relative;
`;

export const LangSelectOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const LangSelect = styled.div`
  position: absolute;
  right: 0;
  top: -3rem;
  display: flex;
  flex-direction: column;
  box-shadow: 8px 8px 24px 0 rgba(66, 68, 90, 0.5);
`;
