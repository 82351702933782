import decodeJwt from 'jwt-decode';
import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
} from '@firebase/auth';
import { auth } from '../firebase';

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email: string, password: string) => {
  // Assert email or password is not empty
  if (!(email.length > 0)) {
    throw new Error('Email was not provided');
  }
  if (!(password.length > 0)) {
    throw new Error('Password was not provided');
  }

  await signInWithEmailAndPassword(auth(), email, password);
  return true;
};

/**
 * Login to backend using a generated custom impersonation token and store JWT on success
 *
 * @param customToken
 * @returns true if user successfully signed in
 * @throws Error on http errors or failed attempts
 */
export const loginWithCustomToken = async (customToken: string) => {
  // Assert custom token is not empty
  if (!(customToken.length > 0)) {
    throw new Error('Custom token was not provided');
  }

  try {
    await signInWithCustomToken(auth(), customToken);
  } catch (error) {
    throw new Error(`${error}`);
  }
  return true;
};

/**
 * Sign up via backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @param passwordConfirmation
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const signUp = async (
  email: string,
  password: string,
  passwordConfirmation: string
) => {
  // Assert email or password or password confirmation is not empty
  if (!(email.length > 0)) {
    throw new Error('Email was not provided');
  }
  if (!(password.length > 0)) {
    throw new Error('Password was not provided');
  }
  if (!(passwordConfirmation.length > 0)) {
    throw new Error('Password confirmation was not provided');
  }

  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request('/api/signup', {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();
  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    const decodedToken: any = decodeJwt(data.access_token);
    localStorage.setItem('token', data.access_token);
    localStorage.setItem('permissions', decodedToken.permissions);
  }

  return data;
};

export const handleLogout = () => {
  signOut(auth())
    .then(() => {
      // Sign-out successful.
      window.location.reload();
    })
    .catch((error) => {
      alert(`${error.code}: ${error.message}`);
    });
};
