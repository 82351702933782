import { createTheme } from '@mui/material';
import themeColors from './utils/theme';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  palette: {
    primary: {
      main: themeColors.greenBrand,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: 28,
          fontSize: 18,
          height: 56,
          padding: '0 24px',
          ':hover': {
            boxShadow: 'none',
            backgroundColor: themeColors.greenHover,
          },
          ':active': { backgroundColor: themeColors.greenPressed },
        },
      },
    },
  },
});

export default theme;
