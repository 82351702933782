import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import smartlookClient from 'smartlook-client';
import App from './App';
import './i18n';
import { appConfig } from './config';
import { store } from './redux-file/store';
import { injectStore } from './utils/api-client';
import { QueryClient, QueryClientProvider } from 'react-query';
import registerFonts from './utils/fonts';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import ToastProvider from './ToastProvider';
import Suspense from './Suspense';
import DisabledApp from './DisabledApp';

injectStore(store); /* inject store into axios clients */
registerFonts(); /* registers fonts */

if (appConfig.environment === 'production') {
  smartlookClient.init(appConfig.smartlookApiKey, { region: 'eu' });
}

Sentry.init({
  dsn: appConfig.sentryIngestUrl,
  environment: `${appConfig.environment}${appConfig.stage || ''}`,
  attachStacktrace: true,
  integrations: [new BrowserTracing()],
  release: appConfig.sentryRelease,
  tracesSampleRate:
    appConfig.environment === 'production' ||
      appConfig.environment === 'staging'
      ? 1.0
      : 0.0,
});

const Wrapper = ({ children }: any) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <DisabledApp />
      </Router>
    </ThemeProvider>
  </Provider>
);
