/* eslint-disable import/prefer-default-export */
import { createAction, PrepareAction } from '@reduxjs/toolkit';

type SetLoggedInParam = boolean | undefined;

export const setLoggedIn = createAction<SetLoggedInParam>('setLoggedIn');
export const logout = createAction<PrepareAction<SetLoggedInParam>>(
  'setLoggedIn',
  () => ({ payload: false })
);
