import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createTargetAPI,
  deleteTargetAPI,
  deleteTaskListAPI,
  getOrganizationAPI,
  getRecommendationsAPI,
  getStatsTargetCompletedAPI,
  getStatsTargetTotalAPI,
  getTargetAPI,
  getTargetEmissionAPI,
  getTargetsAPI,
  getTaskListAPI,
  patchTaskListAPI,
  postTaskListAPI,
  updateTargetAPI,
  getAboutCompanyPeriodDataAPI,
  putAboutCompanyPeriodDataAPI,
  getConsentsApi,
  putConsentsApi,
  putLangAPI,
} from '../utils';
import { RootState } from './store';
import { ProductPlatform } from '../utils/api.interfaces';
import { ReportingFreq } from './interfaces';

export const getTargets = createAsyncThunk(
  'getTargets',
  async ({ organizationId }: { organizationId: number }) =>
    await getTargetsAPI(organizationId)
);

export const getTarget = createAsyncThunk(
  'getTarget',
  async ({
    organizationId,
    targetId,
  }: {
    organizationId: number;
    targetId: string;
  }) => await getTargetAPI(organizationId, targetId)
);

export const deleteTarget = createAsyncThunk(
  'deleteTarget',
  async ({
    organizationId,
    targetId,
  }: {
    organizationId: number;
    targetId: string;
  }) => await deleteTargetAPI(organizationId, targetId)
);

export const createTarget = createAsyncThunk(
  'createTarget',
  async (data: {
    orgId: number;
    commit: boolean;
    method: string;
    start_period: string;
    end_period: string;
    target_value: number;
    comment: string;
    domain: string;
    id?: number;
  }) => {
    const {
      orgId,
      commit,
      id,
      method,
      start_period,
      end_period,
      target_value,
      comment,
      domain,
    } = data;
    return await createTargetAPI(
      orgId,
      commit,
      method,
      start_period,
      end_period,
      target_value,
      comment,
      domain,
      id
    );
  }
);

export const updateTarget = createAsyncThunk(
  'updateTarget',
  async (data: {
    commit: boolean;
    orgId: number;
    targetId: string;
    method?: string;
    end_period?: string;
    domain?: string;
    target_value?: number;
    comment?: string;
  }) => {
    const {
      commit,
      orgId,
      targetId,
      method,
      end_period,
      domain,
      target_value,
      comment,
    } = data;
    return await updateTargetAPI(
      orgId,
      targetId,
      commit,
      method,
      end_period,
      domain,
      target_value,
      comment
    );
  }
);

export const getRecommendations = createAsyncThunk(
  'getRecommendations',
  async (orgId: number) => await getRecommendationsAPI(orgId)
);

export const getStatsTargetTotal = createAsyncThunk(
  'getStatsTargetTotal',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { organizationId } = state.session;
    return await getStatsTargetTotalAPI(organizationId);
  }
);

export const getStatsTargetCompleted = createAsyncThunk(
  'getStatsTargetCompleted',
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { organizationId } = state.session;
    return await getStatsTargetCompletedAPI(organizationId);
  }
);

export const getOrganization = createAsyncThunk(
  'getOrganization',
  async ({
    orgId,
    products,
  }: {
    orgId: number;
    products?: ProductPlatform[];
  }) => await getOrganizationAPI(orgId, products || [])
);

export const putLanguage = createAsyncThunk(
  'putLanguage',
  async (language: string) => await putLangAPI(language)
);

export const getTargetEmission = createAsyncThunk(
  'getTargetEmission',
  async (emissionType: string) => await getTargetEmissionAPI(emissionType)
);

export const getTaskList = createAsyncThunk(
  'getTaskList',
  async (orgId: number) => await getTaskListAPI(orgId)
);

export const postTaskList = createAsyncThunk(
  'postTaskList',
  async (title: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { organizationId } = state.session;
    return postTaskListAPI(organizationId, title);
  }
);

export const patchTaskList = createAsyncThunk(
  'putTaskList',
  async ({ id, checked }: { id: number; checked: boolean }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const { organizationId } = state.session;
    return patchTaskListAPI(organizationId, id, checked);
  }
);

export const deleteTaskList = createAsyncThunk(
  'deleteTaskList',
  async (id: number) => {
    await deleteTaskListAPI(id);
    return { id };
  }
);

export const setSelectedDate = createAsyncThunk(
  'setSelectedDate',
  async (date: Date) => date
);

export const getAboutCompanyPeriodData = createAsyncThunk(
  'getAboutCompanyPeriodData',
  async ({
    organizationId,
    period,
  }: {
    organizationId: number;
    period: string;
  }) => await getAboutCompanyPeriodDataAPI(organizationId, period)
);

export const putAboutCompanyPeriodData = createAsyncThunk(
  'putAboutCompanyPeriodData',
  async ({
    organizationId,
    period,
    numberOfEmployees,
    incomeInReportingPeriod,
    organizationBoundaries,
  }: {
    organizationId: number;
    period: string;
    numberOfEmployees: number | null;
    incomeInReportingPeriod: number | null;
    organizationBoundaries: string | null;
  }) =>
    await putAboutCompanyPeriodDataAPI(
      organizationId,
      period,
      numberOfEmployees,
      incomeInReportingPeriod,
      organizationBoundaries
    )
);

export const getConsents = createAsyncThunk(
  'getConsents',
  async () => await getConsentsApi()
);

export const putConsents = createAsyncThunk(
  'putConsents',
  async ({ data }: { data: any }) => await putConsentsApi(data)
);

export const setReportingPeriod = createAsyncThunk(
  'setReportingPeriod',
  (period: ReportingFreq) => period
);

export const setHaveAccess = createAsyncThunk(
  'SetHaveAccess',
  (access: boolean) => access
);
